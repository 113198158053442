import gql from "graphql-tag";

export const REINCARNATION = gql`
  query reincarnation {
    reincarnations {
      id
    }
  }
`;

export const REINCARNATION_CREATE = gql`
  mutation reincarnationCreate($input: ReincarnationCreateInput!) {
    reincarnationCreate(input: $input) {
      id
    }
  }
`;

export const REINCARNATION_UPDATE = gql`
  mutation reincarnationUpdate($input: ReincarnationCreateInput!) {
    reincarnationCreate(input: $input) {
      id
    }
  }
`;

export const GET_REINCARNATION_USERS = gql`
  query ReincarnationUsers($username: String!) {
    reincarnationUsers(username: $username) {
      id
      username
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;
