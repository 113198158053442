import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { Container } from "@mui/material";
import { REINCARNATION_CREATE, LOGOUT } from "./gql";
import { useMutation } from "@apollo/client";
import CreateReincarnation from "./CreateReincarnation";

const Reincarnation = () => {
  const [
    createReincarnation,
    { error: reincarnationCreateError, loading: reincarnationCreateLoading },
  ] = useMutation(REINCARNATION_CREATE, {});

  const [logout] = useMutation(LOGOUT);

  const handleSubmit = (id: string) => {
    try {
      createReincarnation({
        variables: {
          input: { userToId: parseInt(id) },
        },
      }).then(() => {
        handleLogout();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogout = () => {
    logout().then(() => {
      window.location.reload();
      sessionStorage.clear(); //PENTEST CONF-6
    });
  };

  return (
    <Container maxWidth="sm">
      <Grid container rowSpacing={3} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h1">Převtělení</Typography>
        </Grid>

        {reincarnationCreateError && (
          <Grid item xs={12}>
            <Alert severity="error">{reincarnationCreateError.message}</Alert>
          </Grid>
        )}

        <CreateReincarnation
          handleSubmit={handleSubmit}
          isSubmitting={reincarnationCreateLoading}
        />
      </Grid>
    </Container>
  );
};

export default Reincarnation;
