import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Grid,
  IconButton,
  MenuItem,
  Menu,
  Typography,
  Chip,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import {
  LOGOUT,
  REINCARNATION,
  REINCARNATION_PERMISSION,
  REINCARNATION_UPDATE,
} from "./gql";
import { isAdmin } from "../../utils/user";
import { NavLink } from "react-router-dom";
import { useBoundStore } from "../../utils/stores/BoundStore";

const UserPopupMenu = () => {
  const user = useBoundStore((state) => state.user);

  const setReincarnationPermission = useBoundStore(
    (state) => state.setReincarnationPermission
  );

  const setReincarnation = useBoundStore((state) => state.setReincarnation);

  const isAdminRights = isAdmin(user);

  const [anchorEl, setAnchorEl] = useState(null);

  const [logout] = useMutation(LOGOUT);

  const { loading: reincarnationLoading, data: reincarnationData } =
    useQuery(REINCARNATION);

  const [updateReincarnation] = useMutation(REINCARNATION_UPDATE);

  const {
    // loading: reincarnationPermissionLoading,
    data: reincarnationPermissionData,
  } = useQuery(REINCARNATION_PERMISSION);

  useEffect(() => {
    if (reincarnationPermissionData) {
      //TODO: allow when Backend is ready
      // setReincarnationPermission(
      //   reincarnationPermissionData.reincarnationPermission
      // );
    }
  }, [reincarnationPermissionData, setReincarnationPermission]);

  useEffect(() => {
    if (reincarnationData) {
      setReincarnation(reincarnationData.reincarnation);
    }
  }, [reincarnationData, setReincarnation]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    logout().then(() => {
      window.location.reload();
      sessionStorage.clear(); //PENTEST CONF-6
    });
  };

  const handleCancelReincarnation = () => {
    updateReincarnation({
      variables: {
        input: { id: parseInt(reincarnationData.reincarnation.id) },
      },
    }).then(() => {
      handleLogout();
    });
  };

  if (reincarnationLoading) return "";

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        alignContent={"center"}
        spacing={1}
      >
        <Grid item>
          {reincarnationData && reincarnationData.reincarnation && (
            <Chip label="Převtělení" size="small" color="info" />
          )}
        </Grid>
        <Grid item>
          <Typography
            component="div"
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <strong>{user.username}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Grid>
      </Grid>

      <div>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>{user.username}</MenuItem>
          {isAdminRights && (
            <MenuItem component={NavLink} to="/admin" onClick={handleClose}>
              Administrátor
            </MenuItem>
          )}

          {reincarnationData?.reincarnation && (
            <MenuItem onClick={handleCancelReincarnation}>
              Zrušit převtělení
            </MenuItem>
          )}

          {/* TODO: allow when Backend is ready */}
          {/* {!reincarnationData?.reincarnation && (
            <MenuItem
              component={NavLink}
              to="/reincarnation"
              onClick={handleClose}
            >
              Převtělení
            </MenuItem>
          )} */}

          <MenuItem component={NavLink} to="/help" onClick={handleClose}>
            Nápověda
          </MenuItem>
          <MenuItem
            component={NavLink}
            to="https://neznam.szn.cz/media/document/25/1/smernice-pokladna_seznam.docx"
            onClick={handleClose}
          >
            Směrnice
          </MenuItem>
          <MenuItem onClick={() => handleLogout()}>Odhlásit</MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default UserPopupMenu;
