import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Grid,
  LinearProgress,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import UserPopupMenu from "./UserPopupMenu";
import { useBoundStore } from "../../utils/stores/BoundStore";
import CarmenLogo from "../../images/carmen_logo.png";

const MainAppBar = () => {
  let billingReceiptSplit = /\/billing\/[0-9]+\/receipt\/[0-9]+\/split/i;
  let billingReceipt = /\/billing\/[0-9]+\/receipt\/[0-9]+/i;
  let billingSplit = /\/billing\/[0-9]+\/split/i;
  let billing = /\/billing\/[0-9]+/i;

  const navigate = useNavigate();

  const isAppLoading = useBoundStore((state) => state.isAppLoading);

  const getBackUrl = (location) => {
    const pathname = location.pathname;

    if (billingReceiptSplit.test(pathname)) {
      return -1;
    } else if (billingReceipt.test(pathname)) {
      return -1;
    } else if (billingSplit.test(pathname)) {
      return -1;
    } else if (billing.test(pathname)) {
      //return home
      return "/";
    }
  };

  // const getAppBarTitle = () => {
  //   const pathname = location.pathname;
  //   if (billingReceiptSplit.test(pathname)) {
  //     return "Účtenka / Rozpočet";
  //   } else if (billingReceipt.test(pathname)) {
  //     return "Účtenka";
  //   } else if (billingSplit.test(pathname)) {
  //     return "Vyúčtování / Rozpočet";
  //   } else if (billing.test(pathname)) {
  //     return "Vyúčtování";
  //   }

  //   return "Carmen";
  // };

  return (
    <>
      {isAppLoading && <LinearProgress />}
      <Box>
        <AppBar
          position="static"
          // color="#ffffff"
          // sx={{ borderBottom: "1px solid #b3b3b3" }}
          sx={{ backgroundColor: "#ffffff", color: "grey" }}
        >
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              {/* {location.pathname !== "/" && (
                <Grid item xs={2}>
                  <BackButton to={getBackUrl(location.pathname)} />
                </Grid>
              )} */}
              <Grid item xs={8} textAlign="left">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => navigate("/")}
                >
                  <img src={CarmenLogo} width={130} alt="logo" />
                </IconButton>
              </Grid>

              <Grid item xs={4}>
                <UserPopupMenu />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default MainAppBar;
