import { produce } from "immer";
import { StateCreator } from "zustand";
import { UserType } from "../../types/UserType";
import { MessageSeverity } from "../app";
import { UserTokenType } from "../../types/UserTokenType";
import { ReincarnationPermissionType } from "../../types/ReincarnationPermissionType";

export interface AppStore {
  user: UserType;

  setUser: (userData: UserType) => void;

  popupMessage: {
    message: string;
    severity: string;
  };

  openPopupMessage: (message: string, severity: MessageSeverity) => void;
  closePopupMessage: () => void;

  isAppLoading: boolean;
  setAppLoading: (isAppLoading: boolean) => void;

  userTokens: UserTokenType[];
  selectedToken: string;
  setSelectedToken: (token: string) => void;
  setUserTokens: (userTokens: UserTokenType[]) => void;

  fetchSplitOptionsLoading: boolean;
  setFetchSplitOptionsLoading: (isLoading: boolean) => void;

  reincarnationPermission: ReincarnationPermissionType[];
  setReincarnationPermission: (
    permission: ReincarnationPermissionType[]
  ) => void;

  reincarnation: any;
  setReincarnation: (reincarnationData: any) => void;
}

export const createAppStore: StateCreator<AppStore, [], [], AppStore> = (
  set
) => ({
  user: {
    username: "",
    locale: "",
    authorized: false,
    roles: [{}],
  },
  setUser: (userData) => set(() => ({ user: userData })),

  popupMessage: {
    message: "",
    severity: "",
  },

  openPopupMessage: (message, severity) =>
    set(
      produce((state) => {
        state.popupMessage.severity = severity;
        state.popupMessage.message = message;
      })
    ),

  closePopupMessage: () =>
    set(
      produce((state) => {
        state.popupMessage.severity = null;
        state.popupMessage.message = null;
      })
    ),

  userTokens: [],
  selectedToken: "",

  setSelectedToken: (token) => set(() => ({ selectedToken: token })),

  setUserTokens: (userTokens) => set(() => ({ userTokens: userTokens })),

  fetchSplitOptionsLoading: false,
  setFetchSplitOptionsLoading: (isLoading) =>
    set(() => ({ fetchSplitOptionsLoading: isLoading })),

  isAppLoading: false,

  setAppLoading: (isLoading) =>
    set(
      produce((state) => {
        state.isAppLoading = isLoading;
      })
    ),

  reincarnationPermission: [],
  setReincarnationPermission: (permission) =>
    set(() => ({ reincarnationPermission: permission })),

  reincarnation: null,
  setReincarnation: (reincarnationData) =>
    set(() => ({ reincarnation: reincarnationData })),
});
