import { useLazyQuery } from "@apollo/client";
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { GET_REINCARNATION_USERS } from "./gql";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

interface CreateReincarnationType {
  handleSubmit: (id: string) => void;
  isSubmitting?: boolean;
}

type UserReincarnationType = {
  id: string;
  username: string;
};

const CreateReincarnation = ({
  handleSubmit,
  isSubmitting,
}: CreateReincarnationType) => {
  const [getUsers, { data }] = useLazyQuery(GET_REINCARNATION_USERS);
  const [value, setValue] = useState<UserReincarnationType | null>(null);

  const [optionUsers, setOptionUsers] = useState([]);

  useEffect(() => {
    if (data && data.reincarnationUsers) {
      setOptionUsers(data.reincarnationUsers);
    }
  }, [data]);

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.target && (e.target as HTMLInputElement).value !== "") {
      getUsers({
        variables: { username: (e.target as HTMLInputElement).value },
      });
    }
  };

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Autocomplete
            fullWidth
            filterOptions={(x) => x}
            renderInput={(params) => <TextField {...params} label="Uzivatel" />}
            getOptionLabel={(option: any) => option.username}
            options={optionUsers}
            onKeyUp={handleKeyUp}
            value={value}
            onChange={(event: any, newValue: UserReincarnationType | null) => {
              setValue(newValue);
            }}
          />
        </CardContent>

        <CardActions>
          <LoadingButton
            fullWidth
            disableElevation
            loading={isSubmitting}
            disabled={value === null || isSubmitting}
            onClick={() => {
              if (value) handleSubmit(value.id);
            }}
            variant="contained"
          >
            Převtělit
          </LoadingButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default CreateReincarnation;
